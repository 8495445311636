











































































































































































































































import { Component, Prop} from 'vue-property-decorator';
import LoadingHandler from '@/utils/loading-handler';

import ProductEntity, {ValidatorRules} from '@/entities/product-entity';
import ProductDisplayService from '@/services/product-display-service';
import ProductRepository, {ProductListType} from '@/repositories/company/product-repository';

import cloneDeep from 'lodash/cloneDeep';

import ProductItemEntity from "@/entities/product-item-entity";
import IngredientRepository, {IngredientListType} from "@/repositories/company/ingredient-repository";

import StickyHeader from '@/components/StickyHeader.vue';
import Accordion from '@/components/Accordion.vue';
import ItemSelectTable from './components/ItemSelectTable.vue';

import FooterNote from '../components/FooterNote.vue';
import FooterAttachment from '../components/FooterAttachment.vue';
import FooterHistory from '../components/FooterHistory.vue';
import TagSelect from '@/components/TagSelect.vue';

import { help as PopoverText } from '@/lang/help/product-create';

import IngredientEntity from "@/entities/ingredient-entity";
import {TItemGroupItem} from "@/views/label/companies/product/components/ItemSelectTable.vue";
import ObjectUtils from "@/utils/object-utils";
import CreateLabelPageBase from "@/views/label/companies/CreateLabelPageBase";

import LabelSetting from "./components/LabelSetting.vue";
import {BasicNutritionList} from "@/entities/nutrition-entity";
import ProductLabelEntity from "@/entities/product-label-entity";
import ProductLabelLayoutEntity from "@/entities/product-label-layout-entity";
import CompanyEntity, {CompanyResourceTagType} from "@/entities/company-entity";
import {CompanyRepository} from "@/repositories/company-repository";
import {i18n} from "@/bootstraps/locale";
import { CompanyIntraCategoryProductRepository } from '@/repositories/company/company-intra-category-product-repository';
import {CompanyIntraCategoryProductEntity} from "@/entities/company-intra-category-product-entity";
import ResourceDepartmentSelectSection from "@/views/components/Department/ResourceDepartmentSelectSection.vue";
import ProductDisplayServiceSettingVisibility from "@/services/product-display-service-setting-visibility";
import {AccessLogRepository} from "@/repositories/access-log-repository";
import ProductDispalySettingPanel from "@/views/label/companies/product/components/ProductDispalySettingPanel.vue";
import IconLock from "@/components/Icons/IconLock.vue";
import LockButton from "@/views/label/companies/components/LockButton.vue";
import LabelNutritionSection from "@/components/Project/nutrition/LabelNutritionSection.vue";
import NutritionBasicSection from "@/components/Project/nutrition/NutritionBasicSection.vue";
import {IRecipeComponent, IRecipeComponentItem} from "@/entities/interfaces/i-recipe-component";
import {dispatchInitialized} from "@/libs/cypress";

@Component({
  components: {
    NutritionBasicSection, LabelNutritionSection,
    LockButton,
    IconLock,
    ProductDispalySettingPanel,
    ResourceDepartmentSelectSection,
    StickyHeader,
    Accordion,
    ItemSelectTable,
    LabelSetting,
    FooterNote,
    FooterAttachment,
    FooterHistory,
    TagSelect
  }
})
export default class extends CreateLabelPageBase {
  protected ctor = ProductEntity;
  protected titleLabel:string = i18n.t('商品');
  protected routePrefix:string = 'product';
  protected model:ProductEntity = null as any;

  private company:CompanyEntity = null as any;
  private companyIntraCategoryProductList:CompanyIntraCategoryProductEntity[] = [];

  private readonly PopoverText = PopoverText;
  private readonly CompanyTagType = CompanyResourceTagType;

  private productDisplayService:ProductDisplayService = null as any;
  private settingVisibility:ProductDisplayServiceSettingVisibility = null as any;

  private readonly BasicNutritionList = BasicNutritionList;

  private selection:TItemGroupItem[] = [];

  private ingredientList:IngredientListType[] = [];
  private productList:ProductListType[] = [];
  private labelReferenceProductId:number|null = null;

  protected getRepository() {
    return new ProductRepository(this.companyId);
  }

  private get rules(){
    return ValidatorRules;
  }

  private get isDev():boolean {
    return this.$route.query.dev === 'true';
  }

  private async created() {
    const promises = [
      this.getRepository().list(this.id).then((list) => {
        this.productList= list;
      }),
      (new IngredientRepository(this.companyId)).list().then((list) => {
        this.ingredientList = list;
      }),
      this.initializeModel().then((model: ProductEntity) => {
        this.model = model;
        // first item
        if (!this.model.productItems || !this.model.productItems.length) {
          this.model.productItems.push(new ProductItemEntity({}, this.model));
        }
        // set user's department
        if (this.isCreateMode) {
          this.model.departmentIds = this.$auth.user.departments.map(d => d.id);
        } else {
          (new AccessLogRepository(this.companyId)).logProduct(this.id!);
        }
      }),
      (new CompanyRepository()).find(this.companyId).then(res => {
        this.company = res;
      }),
      (new CompanyIntraCategoryProductRepository(this.companyId)).all().then(res => {
        this.companyIntraCategoryProductList = res;
      }),
    ];

    LoadingHandler(() => {
      return Promise.all(promises).then(() => {
        this.setSelections();

        this.productDisplayService = new ProductDisplayService(this.model!, this.model!.productDisplaySetting, this.company!.setting, this.isDev);
        this.settingVisibility = new ProductDisplayServiceSettingVisibility(this.model!, this.productDisplayService, this.company!.setting);
        this.isAllLoaded = true;
        dispatchInitialized();
      })
    });
  }

  // （部署設定により）listで取得できなかったProductItemsをFillする
  private getOldSelectionAppendedResources() {
    const ings = this.ingredientList.filter(i => !i.isPreproduct);
    const preproducts = this.ingredientList.filter(i => i.isPreproduct);
    const products = [...this.productList];
    this.model!.productItems.forEach(pi => {
      if (pi.isChildProduct) {
        if (!products.find(i => i.id === pi.childProductId)) {
          products.push({...pi.childProduct!, name: `（他部署）${pi.childProduct!.name}`});
        }
      } else if (pi.ingredient) {
        if (pi.ingredient.isPreproduct) {
          if (!preproducts.find(i => i.id === pi.ingredientId)) {
            preproducts.push({...pi.ingredient, name: `（他部署）${pi.ingredient.name}`});
          }
        } else {
          if (!ings.find(i => i.id === pi.ingredientId)) {
            ings.push({...pi.ingredient, name: `（他部署）${pi.ingredient.name}`});
          }
        }
      }
    });
    return {ings, preproducts, products};
  }

  private setSelections() {
    const {ings, preproducts, products} = this.getOldSelectionAppendedResources();
    const parse = (d, prefix) => {
      return {
        key: prefix + d.id,
        id: d.id,
        name: d.name + (d.displayName ? `（${d.displayName}）` : ''),
        search: d.name + (d.displayName || '') + d.intraCode + d.tags.join(''),
      };
    };
    if (!this.model.isAssort) {
      this.selection.push({ key: '原材料', name: this.$t('原材料'), search: this.$t('原材料'), isHeading: true });
      this.selection.push(...ings.map(d => parse(d, IngredientEntity.KEY_PREFIX)));
      this.selection.push({ key: '中間原材料', name: this.$t('中間原材料'), search: this.$t('商品'), isHeading: true});
      this.selection.push(...preproducts.map(d => parse(d, IngredientEntity.KEY_PREFIX_PREPRODUCT)));
      this.selection.push({ key: '商品', name: this.$t('商品'), search: this.$t('商品'), isHeading: true });
      this.selection.push(...products.map(d => parse(d, ProductEntity.KEY_PREFIX)));
    } else {
      this.selection.push({ key: '商品', name: this.$t('商品（アソートの内訳）'), search: this.$t('商品'), isHeading: true });
      this.selection.push(...products.map(d => parse(d, ProductEntity.KEY_PREFIX)));
    }
  }

  private get ingredientText(): string {
    if (!this.model) return '';
    return (new ProductDisplayService(this.model, this.model!.productDisplaySetting, this.company!.setting, this.isDev)).getIngredientNames();
  }

  private onItemChanged(productItem: ProductItemEntity) {
    if (!!productItem.ingredient && productItem.ingredient.isPreproduct) {
      productItem.isCompositeSplitted = true;
    } else {
      productItem.isCompositeSplitted = null;
    }

    if (!!productItem.ingredient) {
      const itemContainsHidden = productItem.ingredient.items.some(i => !i.visible && i.getAllAllergens().length > 0);
      if(itemContainsHidden && !this.model!.productDisplaySetting.isAllergenSummarized) {
        (this.$refs.itemSelectTable as ItemSelectTable&any).showAlertOfAllergenCarryover();
      }
    }

    productItem.carryoverHandler.items.filter(i => i.hidden).forEach(i => i.isCarryover = true);
  }

  private onAllergenSummarizedChanged(val) {
    if (val) return;

    const isAnyItemWithAllergenCarryoverred = this.model!.items.some(pi => {
      return pi.carryoverHandler.items.some(c => c.isCarryover && c.containsAllergen);
    });

    if (isAnyItemWithAllergenCarryoverred) {
      (this.$refs.itemSelectTable as ItemSelectTable&any).showAlertOfAllergenCarryover();
    }
  }

  private showAlert(msg, title) {
    this.$confirm(msg, title, {
      confirmButtonText: this.$t('閉じる'),
      showCancelButton: false,
      confirmButtonClass: 'c-button primary no-focus',
    }).catch(() => {});
    return false;
  }

  private onAssortChanged() {
    this.selection.splice(0);
    this.setSelections();
  }

  private get productContainsIngredients() {
    // なぜかこれがないと、リアルタイムで反映されない
    const dummy = this.model.productItems.map(pi => pi.item);
    return this.model.productItems.some(pi => !!pi.ingredientId);
  }

  private validateChildProductIngredientMixtureOnSelected(selectingChild: IRecipeComponent, originalItem:IRecipeComponentItem){
    const itemsExceptBeingReplaced = this.model!.productItems.filter(pi => pi.key !== originalItem.key);
    if (
      (selectingChild.isProduct && itemsExceptBeingReplaced.some(pi => !pi.isChildProduct) ) ||
      (!selectingChild.isProduct && itemsExceptBeingReplaced.some(pi => pi.isChildProduct) ))
    {
      return this.showAlert('商品の構成要素は、中間原材料もしくは原材料か、あるいはすべて商品にする必要があります。', '原材料と商品は混在できません');
    }
    const product = selectingChild as ProductEntity;
    if (selectingChild.isProduct && product.productItems.some(pi => pi.isChildProduct) ) {
      return this.showAlert(`別の商品が含まれている商品は選択できません（${product.name}）`, '商品の階層エラー');
    }
    if (this.model.isAssort && !selectingChild.isProduct) {
      return this.showAlert(`「詰め合わせ（アソート）」商品は、すべて「商品」で構成する必要があります。`, '商品の選択エラー');
    }
    return true;
  }

  private async submit() {
    const data = this.serialize();

    if (this.$refs.labelSetting) {
      (this.$refs.labelSetting as LabelSetting).storeSelectedPreviewSheetId();
    }
    await this.submitData(data);
  }

  private serialize() {
    const data = cloneDeep(this.model!) as ProductEntity;

    data.productItems = data.productItems
      .filter(pi => !!pi.item && !!pi.amount) // remove empty
      .map(ii => {
        return Object.assign(ObjectUtils.excludeObject(ii), { carryovers: ii.carryoverHandler.serialize() });
      }) as ProductItemEntity[];

    data.attachments = data.attachments.filter(a => a.isAdded || a.__destroy);

    if (!data.isYieldEnabled) {
      data.yieldPercent = null;
      data.isYieldAffectToNutrition = false;
      data.isYieldAsConcentrationReduction = false;
    }

    data.productNutritionOverride = data.productNutritionOverride.serialize();

    data.productLabel = data.productLabel.serialize();

    return data;
  }

  private get isAnyItemSelected() {
    return this.model!.productItems.some(pi => !!pi.item);
  }

  private async importLabelSetting() {
    if(!this.labelReferenceProductId) return;

    await LoadingHandler(async () => {
      const product = await (new ProductRepository(this.companyId).findById(this.labelReferenceProductId!));

      this.$set(this.model!, 'productLabel', new ProductLabelEntity(product.productLabel));
      this.$set(this.model!, 'productLabelLayout', new ProductLabelLayoutEntity(product.productLabelLayout));

      this.model!.isNutritionOverride = product.isNutritionOverride;
      this.model!.productLabelLayout.janCode = null;
    });
  }
}
